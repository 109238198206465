
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import User from '../../../store/entities/user'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import BaseSwitch from "../../../components/Inputs/BaseSwitch.vue";
import BaseRadioGroup from "../../../components/Inputs/BaseRadioGroup.vue";
@Component({
  components: { Modal, BaseButton, BaseCheckbox, BaseSwitch, BaseRadioGroup }
})
export default class EditUser extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) isEditing: Boolean
  title: String = ''
  user: User = new User()
  disabled: boolean = false
  external: boolean = true
  created() {}

  get roles() {
    let roleOptions = [];
    this.$store.state.user.roles.map(function(item) {        
      roleOptions.push({ 
        "label" : item.name,
        "value" : item.normalizedName
      });
    })
    return roleOptions
  }

  async save() { 
    if(this.isEditing){
      await this.editUsuer();
    }
    else{
      await this.createUser();
    }

    this.$emit('save-success')
    this.$emit('input', false)
    this.resetFields()
  }

  async editUsuer(){
    await this.$store.dispatch({
      type: 'user/update',
      data: this.user
    })
  }

  async createUser(){
    await this.$store.dispatch({
      type: 'user/create',
      data: this.user
    })
  }

  @Watch("external")
  isExternalChanged(newValue){
    if (newValue) {
      this.user.authenticationSource = 'External'
      this.user.password = 'YeLDQFxxM9Rm<&l@hPvwXmH~'
      this.user.confirmPassword = this.user.password
    } 
    else {
      this.user.authenticationSource = undefined
      this.user.password = this.$store.state.user.editUser.password
      this.user.confirmPassword = this.$store.state.user.editUser.password
    }
  }

  close() {
    this.$emit('input', false)
    this.resetFields()
  }

  shown() {
    if(this.isEditing){
      this.title = this.L('Editar usuario')
      this.user = Util.extend(true, {}, this.$store.state.user.editUser)
      this.userRule.password.required.required = this.userRule.confirmPassword.required.required = false;
      this.setDisabled()
    }
    else{
      this.title = this.L('Crear nuevo usuario')
      this.user = new User()
      //this.user['roleNames'] = "ROLMINIMO"
      this.userRule.password.required.required = this.userRule.confirmPassword.required.required = true;
    }
    
    this.external = this.user.authenticationSource === "External";
  }

  getValidationState({ dirty, validated, valid = null }) {
    return dirty || validated ? valid : null
  }
  checkFormValidity() {
    const valid = (this.$refs.userForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.user = new User()
    this.$store.commit("user/edit", this.user)
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.Users.Edit', 'OZONE.Users.Create', 'OZONE.Users.Delete')
  }

  userRule = {
    userName: {
      required: { required: true, min: 3 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('UserName')),
      placeholder: this.L('UserName'),
      trigger: 'blur'
    },
    name: {
      required: { required: true, alpha_spaces: true, min: 3 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Name')),
      placeholder: this.L('Name'),
      trigger: 'blur'
    },
    surname: {
      required: { required: true, alpha_spaces: true, min: 3 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Surname')),
      placeholder: this.L('Surname'),
      trigger: 'blur'
    },
    emailAddress: {
      required: { required: true, min: 3, email: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Email')),
      placeholder: this.L('Email'),
      trigger: 'blur'
    },
    password: {
      required: { required: true, min: 8 },
      message: this.L('ThisFieldIsRequired', this.L('Password')),
      placeholder: this.L('Password'),
      trigger: 'blur'
    },
    confirmPassword: {
      required: { required: true, confirmed: 'password', min: 8 },
      message: this.L('ThisFieldIsRequired', this.L('Password')),
      placeholder: this.L('Password'),
      trigger: 'blur'
    }
  }
}
