
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import EditUser from './edit-user.vue'
import PeopleFind from '../../../components/People/people-find.vue'

class PageUserRequest extends PageRequest {
  keyword: string
  isActive: boolean = null //nullable
  from: Date
  to: Date
}

@Component({
  components: {
    EditUser,
    PeopleFind
  }
})
export default class Users extends AbpBase {
  //filters
  pagerequest: PageUserRequest = new PageUserRequest()
  creationTime: Date[] = []

  isEditing: Boolean = false
  createEditModalShow: Boolean = false;

  createModalShow: boolean = false
  editModalShow: boolean = false
  findModalShow: boolean = false

  searchDefinition: {}[] = [
    {
      fieldName: 'Busqueda',
      fieldType: 'text',
      fieldBind: 'keyword',
      value: ''
    },
    {
      fieldName: 'Fecha Creacion',
      fieldType: 'date',
      fieldBind: 'from',
      value: ''
    },
    {
      fieldName: 'Activo',
      fieldType: 'checkbox',
      fieldBind: 'isActive',
      value: ''
    }
  ]

  pagination: any = {}

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value
      } else {
        delete this.pagerequest[element.fieldBind]
      }
    })
    await this.getpage()
    this.findModalShow = false
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit('user/setCurrentPage', pagination.current)
    this.getpage()

    await this.getpage()
  }

  get list() {
    return this.$store.state.user.list
  }
  get loading() {
    return this.$store.state.user.loading
  }
  create() {
    this.createEditModalShow = true
    this.isEditing = false
  }
  editRow(item, index, button) {
    this.$store.commit('user/edit', item)
    this.edit()
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('DeleteUserConfirm'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Sí'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'user/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }
  edit() {
    this.createEditModalShow = true
    this.isEditing = true
  }

  find() {
    this.findModalShow = true
  }

  hide() {
    this.findModalShow = false
  }

  isActiveChange(val: string) {
    if (val === 'Actived') {
      this.pagerequest.isActive = true
    } else if (val === 'NoActive') {
      this.pagerequest.isActive = false
    } else {
      this.pagerequest.isActive = null
    }
  }
  pageChange(page: number) {
    this.$store.commit('user/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('user/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize
    //filters

    if (this.creationTime.length > 0) {
      this.pagerequest.from = this.creationTime[0]
    }
    if (this.creationTime.length > 1) {
      this.pagerequest.to = this.creationTime[1]
    }

    await this.$store.dispatch({
      type: 'user/getAll',
      data: this.pagerequest
    })

    const pagination = { ...this.pagination }
    pagination.total = this.$store.state.user.totalCount
    pagination.pageSize = this.$store.state.user.pageSize
    pagination.current = this.$store.state.user.currentPage
    this.pagination = pagination
  }
  get pageSize() {
    return this.$store.state.user.pageSize
  }
  get totalCount() {
    return this.$store.state.user.totalCount
  }
  get currentPage() {
    return this.$store.state.user.currentPage
  }
  set currentPage(value) {
    this.$store.commit('user/setCurrentPage', value)
    this.getpage()
  }
  columns = [
    {
      title: this.L('Usuario'),
      scopedSlots: { customRender: 'func' }
    },
    {
      title: this.L('Rol'),
      dataIndex: 'roleNames',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: this.L('Activo'),
      dataIndex: 'isActive',
      scopedSlots: { customRender: 'activeUser' }
    },
    {
      title: this.L('Fecha de creación'),
      dataIndex: 'creationTime',
      scopedSlots: { customRender: 'date' }
    },
    {
      scopedSlots: { customRender: 'editBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    },
    {
      scopedSlots: { customRender: 'removeBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    }
  ]
  async created() {
    this.getpage()
    await this.$store.dispatch({
      type: 'user/getRoles'
    })
  }
}
